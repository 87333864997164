import loadScript from '/lib/posten/load-script'

const Chatbot = ({ chatBotUrl, localization, imgUrl}) => {
    const handleClick = () => {
        /**
         * We want to load the scripts for chatPanel and chatBot
         * only after clicking to save initial page loading time.
         * First, load the chatPanel script
         */
        loadScript('https://posten.boost.ai/chatPanel/chatPanel.js', 'chatPanelScript', () => {
            // When chatPanel has loaded, load the chatBot script
            loadScript(chatBotUrl, 'chatbotScript', () => {
                // When that has loaded, show the chatPanel
                window.boost.chatPanel.show()
            })
        })
    }

    const FallbackImage = () => (
        <svg 
        style={{ width: 316, height: 349 }}
        viewBox="0 0 316 349" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        >
        <g clip-path="url(#clip0_3095_31446)">
            <path 
                d="M162.634 89.8945H149.938V43.8945H162.634V89.8945Z" 
                fill="#E32D22"
                />
            <path 
                d="M171.49 89.8945H158.348V41.8945H171.49V89.8945Z" 
                fill="#980000"
                />
            <mask 
                id="mask0_3095_31446" 
                style={{maskType:'luminance'}}
                maskUnits="userSpaceOnUse" 
                x="-111" 
                y="-105" 
                width="567" 
                height="567"
                >
                <path 
                d="M-111 -105H455.929V461.93H-111V-105Z" 
                fill="white"
                />
            </mask>
            <g mask="url(#mask0_3095_31446)">
                <path d="M255.944 299.33H58.9923C27.7003 299.33 2.09766 273.727 2.09766 242.435L5.44031 129.798C5.44031 98.5048 27.7003 72.5941 58.9923 72.5941H255.944C287.236 72.5941 308.368 97.3488 308.368 128.641L308.154 242.175C308.154 273.469 287.236 299.33 255.944 299.33Z" 
                    fill="#E32D22"
                />
                <path d="M107.571 299.33C109.971 299.33 115.664 298.262 112.771 304.792C101.443 330.36 67.2736 336.627 70.4362 343.798C73.6002 350.968 115.375 347.784 137.519 339.98C159.663 332.178 206.906 295.691 206.906 295.691L107.571 299.33Z" 
                    fill="#E32D22"
                />
                <path d="M80.2539 347.449C118.758 351.549 141.107 349.473 170.633 333.443C200.159 317.415 222.225 290.421 222.225 290.421H191.723C191.723 290.421 176.662 309.354 149.506 325.451C122.921 341.214 80.2539 347.449 80.2539 347.449Z" 
                    fill="#980000"
                />
                <path d="M278.196 279.865C284.452 275.166 288.295 268.062 290.141 260.46C292.876 249.197 293.631 237.558 294.181 225.981C295.228 204.042 295.58 182.069 295.237 160.109C294.988 144.094 294.335 127.865 289.621 112.558C287.611 106.02 284.78 99.5703 280.1 94.5837C275.876 90.0837 265.969 75.7423 232.008 72.5943C254.369 71.161 277.613 71.7117 295.569 84.4437C304.639 90.873 311.359 98.6583 312.84 119.948C315.552 158.88 318.232 241.561 311.619 261.19C304.48 282.381 295.324 292.512 278.739 299.33C267.477 303.96 253.157 306.122 240.981 306.122C226.343 306.122 207.052 307.078 198.887 306.413C200.483 306.544 205.909 296.084 206.656 294.565C208.756 290.294 222.224 290.42 222.224 290.42C222.224 290.42 209.84 290.5 216.857 290.445C230.219 290.341 235.037 290.666 248.284 288.922C262.661 287.03 272.685 284.004 278.196 279.865Z" 
                    fill="#980000"
                />
                <path d="M70.5538 72.595C56.6245 73.3404 45.0258 81.543 33.1978 98.2884C26.9365 107.152 24.7271 118.17 23.2805 128.926C20.9285 146.435 15.4751 230.694 21.6365 254.235C28.8205 281.68 47.0258 286.91 58.5605 288.878C76.0791 291.866 115.608 290.42 115.608 290.42C115.608 290.42 116.882 295.692 112.772 304.792C112.47 305.462 101.986 305.487 84.6751 305.655C64.7205 305.847 49.0725 306.34 36.9951 301.226C23.9325 295.692 3.44047 286.267 0.936469 253.547C-0.646198 232.863 0.303137 150.796 2.09914 132.111C3.89514 113.427 6.96314 96.1937 21.8405 84.7484C30.9245 77.7604 39.5591 72.0444 55.7698 72.0444C67.2311 72.0444 70.5538 72.595 70.5538 72.595Z" 
                    fill="#FF8A5F"
                />
                <path d="M188.064 25.6066C188.064 44.9172 171.76 51.2812 158.348 51.2812C144.936 51.2812 139.492 39.0199 139.492 25.6066C139.492 12.1946 154.151 -0.345412 167.512 0.833255C177.059 1.67459 188.064 12.1946 188.064 25.6066Z" 
                    fill="#E32D22"
                />
                <path d="M167.512 0.83325C167.512 0.83325 154.658 -2.11742 145.704 3.64125C139.162 7.84658 134.074 13.8066 133.068 21.5186C131.945 30.1293 133.305 35.3919 138.684 41.8933C144.206 48.5679 150.009 50.0066 158.348 51.2812C162.47 47.8239 161.794 41.9506 154.221 36.8679C151.348 34.9399 148.061 31.1386 147.108 27.0399C145.793 21.3919 147.984 15.2906 150.633 11.6159C157.872 1.58258 167.512 0.83325 167.512 0.83325Z" 
                    fill="#FF8A5F"
                />
                <path d="M105.331 148.003C104.28 148.826 103.327 149.774 102.509 150.831C99.4067 154.842 98.308 160.334 99.6467 165.225C99.9694 166.407 100.431 167.561 101.1 168.587C105.752 175.721 119.811 174.521 124.811 168.501C129.619 162.714 130.981 154.049 125.58 148.047C120.484 142.389 110.853 143.686 105.331 148.003Z" 
                    fill="#980000"
                />
                <path d="M193.887 145.779C191.452 146.751 189.155 148.23 187.62 150.357C185.904 152.735 185.27 155.73 185.051 158.655C184.8 161.999 185.111 165.589 187.11 168.281C188.387 170.002 190.256 171.209 192.238 172.026C198.374 174.557 206.259 173.105 210.255 167.805C212.432 164.917 213.32 161.214 213.343 157.598C213.411 146.862 203.254 142.037 193.887 145.779Z" 
                    fill="#980000"
                />
                <path d="M129.298 208.899C137.119 210.585 145.009 211.925 152.975 212.62C160.599 213.284 168.379 213.473 175.935 212.368C179.699 211.816 183.394 211.592 187.134 210.735C190.681 209.923 194.197 208.964 197.777 208.303C200.25 207.845 203.401 207.399 205.265 209.475C206.805 211.189 206.703 213.539 205.494 215.425C201.469 221.708 185.278 232.223 169.054 235.927C167.963 236.176 166.847 236.32 165.734 236.436C146.841 238.403 125.927 231.44 112.79 217.615C111.255 216 109.791 214.26 108.921 212.209C107.733 209.417 108.053 206.085 111.646 205.365C113.255 205.043 114.911 205.459 116.501 205.865C120.747 206.947 125.013 207.975 129.298 208.899Z" 
                fill="#FFF5F0"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3095_31446">
                <rect style={{ height: 349, width: 316 }} fill="white"/>
            </clipPath>
        </defs>
    </svg>
    
    )

    return (
        <button
            type='button'
            className={`hw-button hw-button--chat-fixed`}
            aria-label={localization.openchat}
            onClick={handleClick}
        >
        {imgUrl ? <img src={imgUrl} /> : <FallbackImage />}
        </button>
    )
}

export default (props) => <Chatbot {...props} />
